@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;700&display=swap');

.aboutHeader{
    text-align: center;
    height: max-content;
    padding: 2%;
    background-color: rgba(220, 230, 177, 0.05);
    overflow: auto;
}

h3{
    text-decoration: none;
    text-align: center;
}


.about{
    padding: 0;
    margin: 0;
    font-size: 3rem;
    font-family: "Open Sans", "Ubuntu", sans-serif;
}

.segment{
    float: left;
}

.example{
    width: 100%;
}