#CContainer{
    padding: 5%;
    text-align: center;
    height: 50vh;
  }

  .Contact{
    text-align: center;
  }

  .Person{
    padding: 5%;
    float: left;
    width: 23.3333%;
    height: 140%;
  }