@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;700&display=swap');

.confidenceBar{
    float: left;
    height: 60%;
    font-family: "Ubuntu", sans-serif;
    border-bottom: 20%;
}

.topEmoji{
    float: left;
    font-size: 14rem;
    height: 40%;
    font-family: "Ubuntu", sans-serif;
    margin-bottom: 0;
}

.historyTitle{
    margin-bottom: 1%;
}
.historyBar{
    float:left;
    font-family: "Ubuntu", sans-serif;
    padding: 0;
    margin-bottom: 20%;
}

h3{
    text-decoration: underline;
}

.wordCloud{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 10%;
    z-index: -5;
}