@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;700&display=swap');

.loadingHeader{
    text-align: center;
    height: 100vh;
    padding: 5%;
}

h3{
    text-decoration: none;
}

.spinLogo{
    text-align: center;
}

.loadingText{
    padding: 0;
    margin: 0;
    font-size: 3rem;
    font-family: "Open Sans", "Ubuntu", sans-serif;
}