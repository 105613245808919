body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.html{
  height: 80%;
}

.body{
  height: 80%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
.pageHeader{
    background-color: #97DBAE;
    background-image: linear-gradient(#97DBAE, #C3E5AE);
    padding: 0.8%;
    position: fixed;
    width: 100%;
}

.logo{
    font-family: 'Noto Sans', sans-serif;
    color: black;
    margin-left: 2%;
    margin-right: 2%;
    font-weight: bold;
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a{
    color:black;
}

.nav-link:hover { text-decoration: underline; }
.nav-link:active { text-decoration: underline; }
.nav-link{
    float: right;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

